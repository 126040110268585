<template>
  <el-dialog
    v-model="model"
    width="620px"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-reconciliation-task-data-source-dialog"
    @close="onDataSourceDialogClose"
  >
    <template #header="{ titleId }">
      <div class="elv-reconciliation-task-data-source-dialog-header__title">
        <h4 :id="titleId">
          {{ isAddDataSourceModel ? t('project.addData') : t('project.editData') }}
        </h4>
        <p>{{ t('project.dataType') }}:{{ t(currentDataTypeInfo?.label || '') }}</p>
      </div>
    </template>
    <div v-loading="dialogLoading" class="elv-reconciliation-task-data-source-dialog-content">
      <el-form ref="dataSourceFormRef" :label-position="'top'" :hide-required-asterisk="false">
        <el-form-item :label="t('project.dataName')">
          <el-input
            v-model="reconciliationTaskDataName"
            class="elv-data-source-name-input"
            clearable
            :placeholder="t('placeholder.pleaseDataName')"
          />
        </el-form-item>
        <el-form-item :label="t('common.filters')" class="elv-reconciliation-task-data-source-dialog-content-filters">
          <div class="elv-reconciliation-task-data-source-dialog-content-filters-items">
            <div
              v-for="(filterItem, filterIndex) in conditionSelectOptions"
              :key="filterItem.type"
              class="elv-reconciliation-task-data-source-dialog-content-filters-items-select"
            >
              <ElvSelect
                v-model="filterItem.type"
                class="elv-reconciliation-task-data-source-filter-item"
                :options="currentSelectOptions"
                :placeholder="`${t('common.select')}...`"
                :disabled="filterItem.type === filterItemTypeEnum.coa_account && isDisabledCheckChartOfAccount"
                width="160px"
                @change="onFilterTypeChange(filterIndex)"
              />
              <el-select
                v-if="filterItem.type === filterItemTypeEnum.type"
                v-model="filterItem.value"
                multiple
                clearable
                collapse-tags
                collapse-tags-tooltip
                :max-collapse-tags="3"
                :placeholder="`${t('common.select')}...`"
                class="elv-reconciliation-task-data-source-filter-type"
                popper-class="elv-reconciliation-task-data-source-filter-type-dropdown"
                :popper-append-to-body="false"
                style="width: 380px"
                @change="onCheckedTypeChange"
              >
                <template v-for="(item, index) in transactionType" :key="index">
                  <el-checkbox
                    v-model="typeSelectData[`checkAll${item.type}`]"
                    class="elv-select-transaction-filter-type__all"
                    :indeterminate="typeSelectData[`isIndeterminate${item?.type}`]"
                    @change="onCheckTypeClassifyAllChange(filterItem, item?.type, index, $event as boolean)"
                    >{{ t(item?.label) }}</el-checkbox
                  >
                  <el-checkbox-group v-model="filterItem.value">
                    <el-option v-for="i in item?.list" :key="i.value" :label="t(i?.label)" :value="i?.value">
                      <el-checkbox style="pointer-events: none" :value="i?.value">{{ t(i?.label) }}</el-checkbox>
                    </el-option>
                  </el-checkbox-group>
                </template>
              </el-select>
              <SingleChoiceSelect
                v-if="filterItem.type === filterItemTypeEnum.platform"
                v-model="filterItem.value"
                class="elv-reconciliation-task-data-source-filter-platform"
                width="380px"
                multiple
                clearable
                filterable
                collapse-tags
                collapse-tags-tooltip
                :max-collapse-tags="2"
                :options="platformList"
                :popper-append-to-body="false"
              />
              <ElvSelect
                v-if="filterItem.type === filterItemTypeEnum.treasury_account"
                v-model="filterItem.value"
                class="elv-reconciliation-task-data-source-filter-account"
                width="380px"
                multiple
                clearable
                filterable
                collapse-tags
                collapse-tags-tooltip
                :max-collapse-tags="3"
                :options="accountList"
                :popper-append-to-body="false"
              />
              <SingleChoiceSelect
                v-if="filterItem.type === filterItemTypeEnum.categorizing"
                v-model="filterItem.value"
                class="elv-reconciliation-task-data-source-filter-account"
                width="380px"
                :options="categorizedSelectOptions"
                :prefixText="t('common.is')"
                :popper-append-to-body="false"
              />
              <SingleChoiceSelect
                v-if="filterItem.type === filterItemTypeEnum.asset || filterItem.type === filterItemTypeEnum.currency"
                v-model="filterItem.value"
                placeholder="Input currency code"
                class="elv-reconciliation-task-data-source-filter-currency"
                width="380px"
                multiple
                clearable
                remote
                remote-show-suffix
                reserve-keyword
                collapse-tags
                collapse-tags-tooltip
                filterable
                :loading="searchCurrencyLoading"
                :remote-method="remoteCurrencyMethod"
                :is-remote-icon="true"
                :max-collapse-tags="3"
                :options="currencyOptions"
                :popper-append-to-body="false"
              />
              <SingleChoiceSelect
                v-if="filterItem.type === filterItemTypeEnum.dr_cr"
                v-model="filterItem.value"
                :placeholder="`${t('common.select')}...`"
                class="elv-reconciliation-task-data-source-filter-dr-cr"
                width="380px"
                multiple
                clearable
                filterable
                remote
                remote-show-suffix
                reserve-keyword
                :is-remote-icon="true"
                :options="drAndCrSelectOptions"
                :popper-append-to-body="false"
              />
              <ElvCascade
                v-if="filterItem.type === filterItemTypeEnum.coa_account"
                v-model:cascadeModel="filterItem.value"
                :isTagType="true"
                :enabledFilter="false"
                :selectSingleGroup="false"
                :isMultipleSelect="false"
                :isHighLight="false"
                :isDisabled="isDisabledCheckChartOfAccount"
                selectWeight="380px"
                :cascadeOptions="chartOfAccountOptions"
                cascadeOptionValueName="chartOfAccountId"
                :cascadeOptionLabelName="locale === 'en' ? 'name' : 'nameCN'"
                cascadeOptionChildName="list"
                :selectPlaceholder="t('common.select') + '...'"
                @onChangeSelectCascadeOptions="onChangeChartOfAccount"
              />
              <template v-if="filterItem.type === filterItemTypeEnum.auxiliaryCode">
                <SingleChoiceSelect
                  v-model="filterItem.auxiliaryTypeId"
                  :placeholder="`${t('common.select')}...`"
                  class="elv-reconciliation-task-data-source-filter-auxiliary-code"
                  width="120px"
                  :options="auxiliaryCodeTreeOptions"
                  :popper-append-to-body="false"
                  @change="onAuxiliaryCodeTreeChange(filterIndex)"
                />
                <SingleChoiceSelect
                  v-model="filterItem.operator"
                  class="elv-reconciliation-task-data-source-filter-auxiliary-code"
                  width="105px"
                  :options="auxiliaryCodeOperatorOptions"
                  :popper-append-to-body="false"
                  @change="onAuxiliaryCodeOperatorChange(filterIndex)"
                />
                <SingleChoiceSelect
                  v-model="filterItem.value"
                  :placeholder="`${t('common.select')}...`"
                  class="elv-reconciliation-task-data-source-filter-default"
                  width="139px"
                  multiple
                  clearable
                  remote
                  remote-show-suffix
                  reserve-keyword
                  collapse-tags
                  :disabled="filterItem.operator === 'EXCLUDES'"
                  collapse-tags-tooltip
                  :filterable="!isNotSelectCounterparty(filterItem.auxiliaryTypeId)"
                  :remote-method="
                    (query: string) => {
                      remoteCounterpartyMethod(query, filterIndex)
                    }
                  "
                  :loading="searchCounterPartyLoading"
                  :is-remote-icon="true"
                  :max-collapse-tags="1"
                  :options="filterItem.options"
                  :popper-append-to-body="false"
                />
              </template>
              <SingleChoiceSelect
                v-if="!filterItem.type"
                v-model="filterItem.value"
                :placeholder="`${t('common.select')}...`"
                class="elv-reconciliation-task-data-source-filter-default"
                width="380px"
                :options="[]"
                :popper-append-to-body="false"
              />
              <SvgIcon
                v-show="!(filterItem.type === filterItemTypeEnum.coa_account && isDisabledCheckChartOfAccount)"
                class="elv-reconciliation-task-data-source-dialog-content-filters-items-delete-btn"
                name="sources-delete"
                width="16"
                height="16"
                :fill="'#838D95'"
                @click="removeFilterDataSource(filterIndex)"
              />
            </div>
          </div>
          <div
            class="elv-reconciliation-task-data-source-dialog-content-filters-add-btn"
            :class="{ 'is-disabled': isDisabledAddConditionFilter }"
            @click="addFilterDataSource"
          >
            <SvgIcon
              name="add-line"
              width="14"
              height="14"
              :fill="isDisabledAddConditionFilter ? '#aaafb6' : '#1753eb'"
            />{{ t('common.addFilter') }}
          </div>
        </el-form-item>
        <p v-if="!isAddDataSourceModel" class="elv-reconciliation-task-data-source-dialog-content-tip">
          {{ t('message.editTaskSourceDataTipInfo') }}
        </p>
      </el-form>
      <el-button
        type="primary"
        round
        class="elv-reconciliation-task-data-source-dialog-save-btn"
        :disabled="isDisabledSaveBtn"
        :loading="saveLoading"
        @click="saveDataSource"
        >{{ t('button.save') }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { $t } from '@/i18n'
import { useI18n } from 'vue-i18n'
import ConfigApi from '@/api/ConfigApi'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import { transactionType } from '@/config/index'
import { CurrencyItemType } from '#/ReportsTypes'
import { ChartOfAccountItemType } from '#/LedgerTypes'
import ReconciliationApi from '@/api/ReconciliationApi'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import ElvCascade from '@/components/Base/ElvCascade.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { DataTypeForReconciliation } from '@/config/reconciliation'
import { isEmpty, difference, capitalize, cloneDeep } from 'lodash-es'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import { ReconciliationTaskDataSetEnum, ReconciliationTaskDataSetItemDetailType } from '#/ReconciliationTypes'

type conditionDataSourceType = {
  type: string
  auxiliaryTypeId: string
  value: any
  operator: string
  options: Array<any>
}

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {} as ReconciliationTaskDataSetItemDetailType
    }
  },
  dataSourceType: {
    type: String,
    default: ReconciliationTaskDataSetEnum.TRANSACTION
  }
})

const emit = defineEmits(['onConfirmSaveDataSource'])

const route = useRoute()
const { t, locale } = useI18n()
const entityStore = useEntityStore()
const accountStore = useAccountStore()
const reconciliationStore = useReconciliationStore()
const model = defineModel<boolean>({ required: true })
const { reconciliationTaskDataSetList } = toRefs(reconciliationStore)

const saveLoading = ref(false)
const agreeRemoved = ref(false)
const dialogLoading = ref(false)
const searchCurrencyLoading = ref(false)
const chartOfAccountList = ref<any[]>([])
const reconciliationTaskDataName = ref('')
const counterpartyOptions = ref<any[]>([])
const searchCounterPartyLoading = ref(false)
const auxiliaryCodeTreeOptions = ref<any[]>([])
const originCounterpartyOptions = ref<any[]>([])
const currenciesData = ref<CurrencyItemType[]>([])
const recommendCurrencyList = ref<CurrencyItemType[]>([])
const chartOrAccountData = ref<ChartOfAccountItemType[]>([])
const conditionSelectOptions = ref<conditionDataSourceType[]>([])

const typeSelectData: any = ref({
  checkAllChain: false,
  checkAllBank: false,
  checkAllExchange: false,
  checkAllCustody: false,
  checkAllPayment: false,
  isIndeterminateChain: false,
  isIndeterminateBank: false,
  isIndeterminateExchange: false,
  isIndeterminateCustody: false,
  isIndeterminatePayment: false,
  lastTimeData: []
})

const filterItemTypeEnum = {
  type: 'TRANSACTION_TYPE',
  platform: 'PLATFORM',
  treasury_account: 'ENTITY_ACCOUNT',
  asset: 'CURRENCY',
  dr_cr: 'BALANCE_TYPE',
  coa_account: 'CHART_OF_ACCOUNT',
  auxiliaryCode: 'AUXILIARY',
  currency: 'CURRENCY',
  categorizing: 'IS_CATEGORIED'
}

const transferSelectOptions = [
  { label: $t('common.type'), value: filterItemTypeEnum.type, isDisabled: false },
  { label: $t('common.platform'), value: filterItemTypeEnum.platform, isDisabled: false },
  { label: $t('common.account'), value: filterItemTypeEnum.treasury_account, isDisabled: false },
  { label: $t('report.Base Asset'), value: filterItemTypeEnum.asset, isDisabled: false },
  { label: $t('common.categorizing'), value: filterItemTypeEnum.categorizing, isDisabled: false }
]

const legerSelectOptions = [
  { label: $t('report.Dr/Cr'), value: filterItemTypeEnum.dr_cr, isDisabled: false },
  { label: $t('report.Account'), value: filterItemTypeEnum.coa_account, isDisabled: false },
  { label: $t('report.auxiliaryCode'), value: filterItemTypeEnum.auxiliaryCode, isDisabled: false },
  { label: $t('report.originalCurrency'), value: filterItemTypeEnum.currency, isDisabled: false },
  { label: $t('common.categorizing'), value: filterItemTypeEnum.categorizing, isDisabled: false }
]

const auxiliaryCodeOperatorOptions = [
  { label: $t('common.in'), value: 'IN' },
  { label: $t('common.Is empty'), value: 'EXCLUDES' }
]

const drAndCrSelectOptions = [
  { label: 'Dr', value: 'DR' },
  { label: 'Cr', value: 'CR' }
]

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const isAddDataSourceModel = computed(() => {
  return isEmpty(props.currentData)
})

const currencyOptions = computed(() => {
  return currenciesData.value.map((item: any) => {
    return {
      value: item?.symbol,
      id: item?.underlyingCurrencyId,
      label: item?.showSymbol,
      icon: item?.logo,
      alt: item?.name
    }
  })
})

const platformList = computed(() => {
  return entityStore.platformList.map((item: any) => {
    return {
      value: item.platformId,
      label: item.name,
      icon: item.logo
    }
  })
})

const accountList = computed(() => {
  const list = !isAddDataSourceModel.value
    ? accountStore.accountList
    : accountStore.accountList.filter((item: any) => item.status === 'NORMAL')
  return list.map((item: any) => {
    return {
      id: item.entityAccountId,
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      alt: item.platform?.name,
      isDeleted: item.status !== 'NORMAL'
    }
  })
})

const chartOfAccountOptions = computed(() => {
  let list = [...entityStore.chartOfAccountOriginList]
  list = list.filter((item) => ['ASSET', 'LIABILITY'].includes(item.type))
  for (const accountItem of list) {
    accountItem.nameCN = accountItem.nameCN ?? accountItem.name
    accountItem.list = accountItem.list.filter(
      (childItem) => childItem.currencyTypes?.length && childItem.currencyTypes[0] !== 'REPORTING'
    )
    accountItem.list.forEach((item) => {
      item.nameCN = item.nameCN ?? item.name
    })
  }
  return list
})

const currentDataTypeInfo = computed(() => {
  return DataTypeForReconciliation.find((item: any) => item.value === props.dataSourceType)
})

const categorizedSelectOptions = computed(() => {
  let label = ''
  switch (props.dataSourceType) {
    case ReconciliationTaskDataSetEnum.TRANSACTION:
      label = $t('title.notCategorized')
      break
    case ReconciliationTaskDataSetEnum.LEDGER:
      label = $t('title.notCreatedByCategorizing')
      break
    default:
      break
  }
  return [{ value: false, label }]
})

const isDisabledAddConditionFilter = computed(() => {
  if (props.dataSourceType === ReconciliationTaskDataSetEnum.TRANSACTION) {
    return conditionSelectOptions.value.length > 4
  }
  const isHasEmptyFilterType = conditionSelectOptions.value.some((item: any) => !item.type)
  if (isHasEmptyFilterType) return true
  const coaAccount = conditionSelectOptions.value.find((item: any) => item.type === filterItemTypeEnum.coa_account)
  if (coaAccount && coaAccount.value?.length) {
    const auxiliaryCodeSelectLength = auxiliaryCodeTreeOptions.value?.length || 0
    return conditionSelectOptions.value.length > 3 + auxiliaryCodeSelectLength
  }
  return conditionSelectOptions.value.length > 3
})

const isDisabledSaveBtn = computed(() => {
  const isEmptyFilterItem = conditionSelectOptions.value.some((item: any) => {
    return (
      !item.type ||
      (item.type &&
        item.type !== filterItemTypeEnum.categorizing &&
        item.operator !== 'EXCLUDES' &&
        !item.value?.length) ||
      (item.type && item.type === filterItemTypeEnum.categorizing && item.value === '') ||
      (item.type &&
        item.type === filterItemTypeEnum.auxiliaryCode &&
        item.operator === 'EXCLUDES' &&
        item.value?.length)
    )
  })
  const isHasChartOfAccount = conditionSelectOptions.value.some((item: any) => {
    return item.type === filterItemTypeEnum.coa_account && item.value?.length
  })
  const isEmptySelect =
    props.dataSourceType === ReconciliationTaskDataSetEnum.TRANSACTION ? false : !isHasChartOfAccount
  return !reconciliationTaskDataName.value || isEmptyFilterItem || isEmptySelect
})

const isNotSelectCounterparty = computed(() => (typeId: any) => {
  const currentTypeInfo = auxiliaryCodeTreeOptions.value.find((treeItem: any) => treeItem.value === typeId)
  return !currentTypeInfo || currentTypeInfo.type !== 'COUNTERPARTY'
})

const isDisabledCheckChartOfAccount = computed(() => {
  if (isAddDataSourceModel.value) {
    return chartOfAccountList.value.length > 0
  }
  return chartOfAccountList.value.length > 1
})

const hasChild = (element: any) => {
  return element && element?.list && element?.list?.length > 0
}

const findChartOfAccountById = (tree: Array<any>, id: string): any | null => {
  if (!Array.isArray(tree)) {
    return null
  }

  for (const element of tree) {
    if (element.chartOfAccountId === id) {
      return element
    }
    if (hasChild(element)) {
      const childRes = findChartOfAccountById(element?.list, id)
      if (childRes) {
        return childRes
      }
    }
  }
  return null
}

const currentSelectOptions = computed(() => {
  const currentModelOptions =
    props.dataSourceType === ReconciliationTaskDataSetEnum.TRANSACTION ? transferSelectOptions : legerSelectOptions
  const selectedInfo = conditionSelectOptions.value.map((item: any) => item.type)
  for (const item of currentModelOptions) {
    if (item.value === filterItemTypeEnum.auxiliaryCode) {
      const accountInfo = conditionSelectOptions.value.find(
        (conditionItem: any) => conditionItem.type === filterItemTypeEnum.coa_account
      )
      const selectAuxiliaryCodeList = conditionSelectOptions.value.filter(
        (conditionItem: any) => conditionItem.type === filterItemTypeEnum.auxiliaryCode
      )
      item.isDisabled =
        !accountInfo ||
        !accountInfo.value?.length ||
        !auxiliaryCodeTreeOptions.value?.length ||
        selectAuxiliaryCodeList?.length >= auxiliaryCodeTreeOptions.value?.length
    } else {
      item.isDisabled = selectedInfo.includes(item.value)
    }
  }
  return currentModelOptions
})

const currentCounterPartyOptions = computed(() => {
  return counterpartyOptions.value.map((item: any) => {
    return {
      value: item.counterpartyId,
      id: item.counterpartyId,
      label: item.name
    }
  })
})

const deleteConditionAuxiliary = () => {
  /* eslint-disable no-plusplus */
  for (let i = 0; i < conditionSelectOptions.value.length; i++) {
    if (conditionSelectOptions.value[i].type === filterItemTypeEnum.auxiliaryCode) {
      conditionSelectOptions.value.splice(i, 1)
      i--
    }
  }
}

/**
 * 替换当前counterPartyOptions可选项
 */
const replaceCounterPartyOptions = (filterIndex: number) => {
  const conditionSelectItem = conditionSelectOptions.value[filterIndex]
  if (conditionSelectItem) {
    conditionSelectItem.options = currentCounterPartyOptions.value
  }
}

/**
 * @description: 远程搜索Counterparty
 * @param {string} query
 */
const remoteCounterpartyMethod = async (query: any, filterIndex: number) => {
  if (query) {
    try {
      searchCounterPartyLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
      replaceCounterPartyOptions(filterIndex)
    } catch (error) {
      console.log(error)
    } finally {
      searchCounterPartyLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
    replaceCounterPartyOptions(filterIndex)
  }
}

/**
 * 科目切换时，重置辅助核算字段选择列表
 */
const onAccountChange = (chartOfAccountId: string) => {
  const chartOfAccountInfo = findChartOfAccountById(chartOrAccountData.value, chartOfAccountId)
  if (!chartOfAccountInfo) {
    auxiliaryCodeTreeOptions.value = []
    return
  }
  auxiliaryCodeTreeOptions.value = chartOfAccountInfo?.auxiliaryTypes || []
  auxiliaryCodeTreeOptions.value = auxiliaryCodeTreeOptions.value.map((item: any) => {
    let auxiliaryCodeList = []
    if (item.type === 'ITEM') {
      auxiliaryCodeList = item?.auxiliaryItems.map((txtItem: any) => {
        return {
          value: txtItem?.auxiliaryItemId,
          id: txtItem?.auxiliaryItemId,
          label: txtItem?.value
        }
      })
    } else if (item.type === 'ENTITY_ACCOUNT') {
      auxiliaryCodeList = accountList.value
    } else {
      auxiliaryCodeList = []
    }
    return {
      value: item?.auxiliaryTypeId,
      id: item?.auxiliaryTypeId,
      type: item.type,
      label: item?.name,
      isDisabled: false,
      list: auxiliaryCodeList
    }
  })
}

/**
 * 切换账号时，更新辅助核算字段选择列表
 */
const onChangeChartOfAccount = () => {
  const chartOfAccount = conditionSelectOptions.value.find((item: any) => item.type === filterItemTypeEnum.coa_account)
  deleteConditionAuxiliary()
  if (!chartOfAccount || !chartOfAccount?.value?.length) {
    auxiliaryCodeTreeOptions.value = []
    return
  }
  const chartOfAccountId = chartOfAccount.value
  chartOfAccount.options = []
  onAccountChange(chartOfAccountId)
}

/**
 * 切换辅助核算上层节点数时，更新辅助核算字段选择列表
 */
const onAuxiliaryCodeTreeChange = (filterIndex: number) => {
  const conditionSelectItem = conditionSelectOptions.value[filterIndex]
  if (conditionSelectItem) {
    conditionSelectItem.value = []
    conditionSelectItem.operator = 'IN'
    const auxiliaryCodeTypeSelected = conditionSelectOptions.value
      .filter((filterItem: conditionDataSourceType) => filterItem.type === filterItemTypeEnum.auxiliaryCode)
      .map((item: conditionDataSourceType) => item.auxiliaryTypeId)
    for (const treeItem of auxiliaryCodeTreeOptions.value) {
      treeItem.isDisabled = auxiliaryCodeTypeSelected.includes(treeItem.value)
    }
    const auxiliaryCodeTreeInfo = auxiliaryCodeTreeOptions.value.find(
      (item: any) => item.value === conditionSelectItem.auxiliaryTypeId
    )
    if (auxiliaryCodeTreeInfo.type !== 'COUNTERPARTY') {
      conditionSelectItem.options = auxiliaryCodeTreeInfo.list
    } else {
      conditionSelectItem.options = currentCounterPartyOptions.value
    }
  }
}

/**
 * @description: 辅助核算字段操作符变化时，数据初始化
 * @param {number} filterIndex
 */
const onAuxiliaryCodeOperatorChange = (filterIndex: number) => {
  const conditionSelectItem = conditionSelectOptions.value[filterIndex]
  if (conditionSelectItem) {
    conditionSelectItem.value = []
  }
}

/**
 * 所选类型变化时，更新下拉选项
 */
const onCheckedTypeChange = (value: any[]) => {
  const differenceType =
    difference(value, typeSelectData.value.lastTimeData)?.[0] ??
    difference(typeSelectData.value.lastTimeData, value)?.[0] ??
    ''
  const type = differenceType ? capitalize(differenceType.split('_')[0]) : ''
  typeSelectData.value.lastTimeData = value
  const typeList = transactionType.find((item: any) => item.type === type)?.list
  if (typeList) {
    const typeDataLength = value.filter((i: any) => capitalize(i.split('_')[0]) === type).length
    typeSelectData.value[`isIndeterminate${type}`] = !!(typeDataLength > 0 && typeDataLength < typeList.length)
    typeSelectData.value[`checkAll${type}`] = typeDataLength === typeList.length
  } else {
    typeSelectData.value[`isIndeterminate${type}`] = false
    typeSelectData.value[`checkAll${type}`] = false
  }
}

/**
 * 类型全选状态改变，数据更新
 */
const onCheckTypeClassifyAllChange = (filterItem: any, type: any, index: number, val: boolean) => {
  typeSelectData.value[`isIndeterminate${type}`] = false
  let newValue
  if (val) {
    newValue = [...filterItem.value, ...transactionType[index].list.map((item: any) => item.value)]
    newValue = Array.from(new Set(newValue))
  } else {
    newValue = filterItem.value.filter(
      (item: any) => !transactionType[index].list.map((i: any) => i.value).includes(item)
    )
  }
  const typeInfo = conditionSelectOptions.value.find((item: any) => item.type === filterItemTypeEnum.type)
  if (typeInfo) {
    typeInfo.value = newValue
  }
  typeSelectData.value.lastTimeData = filterItem.value
}

/**
 * 筛选项发生变化后，数据初始化
 */
const onFilterTypeChange = (filterIndex: number) => {
  const filterItem = conditionSelectOptions.value[filterIndex]
  if (filterItem) {
    filterItem.value = []
  }
  if (props.dataSourceType === ReconciliationTaskDataSetEnum.LEDGER) {
    const coaAccountItem = conditionSelectOptions.value.find(
      (item: any) => item.type === filterItemTypeEnum.coa_account
    )
    if (!coaAccountItem || !coaAccountItem.value?.length) {
      deleteConditionAuxiliary()
    }
  }
  switch (filterItem.type) {
    case filterItemTypeEnum.type:
      typeSelectData.value.checkAllChain = false
      typeSelectData.value.checkAllBank = false
      typeSelectData.value.checkAllExchange = false
      typeSelectData.value.checkAllCustody = false
      typeSelectData.value.checkAllPayment = false
      typeSelectData.value.lastTimeData = []
      break
    case filterItemTypeEnum.coa_account:
      auxiliaryCodeTreeOptions.value = []
      break
    case filterItemTypeEnum.auxiliaryCode:
      filterItem.auxiliaryTypeId = ''
      filterItem.operator = 'IN'
      break
    case filterItemTypeEnum.categorizing:
      filterItem.value = false
      filterItem.operator = 'EQ'
      break
    default:
      break
  }
}

const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    try {
      searchCurrencyLoading.value = true
      const params = {
        recommend: false,
        entityId: entityId.value,
        keywords: [query]
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
    } catch (error) {
      currenciesData.value = []
    } finally {
      searchCurrencyLoading.value = false
    }
  } else {
    currenciesData.value = recommendCurrencyList.value
  }
}

/**
 * 保存编辑对账任务数据源数据
 */
const saveDataSource = async () => {
  const currentSaveData = cloneDeep(conditionSelectOptions.value)
  for (let i = 0; i < currentSaveData.length; i++) {
    const conditionItem: any = currentSaveData[i]
    if (!conditionItem.operator) {
      conditionItem.operator = conditionItem.type === filterItemTypeEnum.coa_account ? 'EQ' : 'IN'
    }
    delete conditionItem?.options
    if (conditionItem.type !== filterItemTypeEnum.auxiliaryCode) {
      delete conditionItem?.auxiliaryTypeId
    }

    switch (conditionItem.type) {
      case filterItemTypeEnum.treasury_account:
        conditionItem.value = conditionItem.value.map((item: any) => {
          return {
            entityAccountId: item
          }
        })
        break
      case filterItemTypeEnum.platform:
        conditionItem.value = conditionItem.value.map((item: any) => {
          return {
            platformId: item
          }
        })
        break
      case filterItemTypeEnum.coa_account:
        conditionItem.value = { chartOfAccountId: conditionItem.value }
        break
      case filterItemTypeEnum.auxiliaryCode:
        const currentAuxiliaryType = auxiliaryCodeTreeOptions.value.find(
          (typeItem: any) => typeItem.value === conditionItem.auxiliaryTypeId
        )
        if (currentAuxiliaryType.type === 'ITEM') {
          conditionItem.value = conditionItem.value.map((item: any) => {
            return {
              auxiliaryItemId: item
            }
          })
        } else if (currentAuxiliaryType.type === 'ENTITY_ACCOUNT') {
          conditionItem.value = conditionItem.value.map((item: any) => {
            return {
              entityAccountId: item
            }
          })
        } else {
          conditionItem.value = conditionItem.value.map((item: any) => {
            return {
              counterpartyId: item
            }
          })
        }
        break
      default:
        break
    }
  }
  const params: any = {
    name: reconciliationTaskDataName.value,
    side: props.dataSourceType === ReconciliationTaskDataSetEnum.TRANSACTION ? 'A' : 'B',
    dataType: props.dataSourceType,
    conditions: currentSaveData
  }
  saveLoading.value = true
  try {
    if (isAddDataSourceModel.value) {
      await ReconciliationApi.addReconciliationTaskDataSet(entityId.value, reconciliationTaskId.value, params)
    } else {
      delete params?.side
      delete params?.dataType
      await ReconciliationApi.updateReconciliationTaskDataSet(
        entityId.value,
        reconciliationTaskId.value,
        props.currentData?.reconciliationDataSetId,
        params
      )
    }
    ElMessage.success(t('message.saveSuccess'))
    emit('onConfirmSaveDataSource')
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

/**
 * 弹窗关闭后处理数据
 */
const onDataSourceDialogClose = () => {
  agreeRemoved.value = false
  conditionSelectOptions.value = []
  chartOfAccountList.value = []
  reconciliationTaskDataName.value = ''
}

/**
 * 添加过滤条件
 */
const addFilterDataSource = () => {
  if (isDisabledAddConditionFilter.value) return
  const newItem = {
    type: '',
    auxiliaryTypeId: '',
    operator: '',
    value: [],
    options: []
  }
  conditionSelectOptions.value.push(newItem)
}

const initDataSource = async () => {
  try {
    dialogLoading.value = true
    accountStore.fetchEntityAccountList(entityId.value, { withDeleted: true })
    const { data } = await ConfigApi.searchCurrencyList({ recommend: true, entityId: 0 })
    recommendCurrencyList.value = data
    const accountData = await LedgerApi.getChartOfAccountList(entityId.value)
    chartOrAccountData.value = accountData.data
    const counterpartyData = await AccountsApi.getCounterpartyList(entityId.value, { limit: 20, page: 1 })
    originCounterpartyOptions.value = counterpartyData.data.list
    counterpartyOptions.value = counterpartyData.data.list
  } catch (error: any) {
    console.log(error.message)
  } finally {
    dialogLoading.value = false
  }
}

/**
 * 移除所选条件
 * @param filterIndex 移除条件索引
 */
const removeFilterDataSource = (filterIndex: number) => {
  const currentInfo = conditionSelectOptions.value[filterIndex]
  if (currentInfo.type === filterItemTypeEnum.coa_account) {
    deleteConditionAuxiliary()
  }
  conditionSelectOptions.value.splice(filterIndex, 1)
}

/**
 * 编辑时重置辅助核算字段类型选择列表
 */
const resetAuxiliaryCodeType = (auxiliaryTypeItem: any) => {
  if (!auxiliaryTypeItem) return
  const currentAuxiliaryItem = {
    value: auxiliaryTypeItem?.auxiliaryTypeId,
    id: auxiliaryTypeItem?.auxiliaryTypeId,
    type: auxiliaryTypeItem.type,
    label: auxiliaryTypeItem?.name,
    isDisabled: false,
    list: []
  }
  auxiliaryCodeTreeOptions.value = [...auxiliaryCodeTreeOptions.value, currentAuxiliaryItem]
}

/**
 * 查找数据集里是否存在科目选项
 */
const findHasCoaAccount = () => {
  chartOfAccountList.value = []
  if (!reconciliationTaskDataSetList.value?.sideBList?.length) return
  const sideBOptions = reconciliationTaskDataSetList.value?.sideBList
  sideBOptions.forEach((item: any) => {
    if (item?.conditions?.length) {
      item.conditions?.forEach((condition: any) => {
        if (condition.type === filterItemTypeEnum.coa_account) {
          chartOfAccountList.value.push(condition)
        }
      })
    }
  })
}

/**
 * 编辑时数据反显
 */
const editDataSetInfo = () => {
  reconciliationTaskDataName.value = props.currentData.name
  const currentConditions = props.currentData.conditions
  if (!isEmpty(currencyOptions)) {
    conditionSelectOptions.value = currentConditions.map((item: any) => {
      const newItem = {
        type: item.type,
        auxiliaryTypeId: item?.auxiliaryTypeId || '',
        operator: item.operator,
        value: item?.value || [],
        options: [] as Array<any>
      }
      if (item.type === filterItemTypeEnum.currency || item.type === filterItemTypeEnum.asset) {
        if (item.underlyingCurrencyList && item.underlyingCurrencyList?.length) {
          item.underlyingCurrencyList?.forEach((currencyItem: any) => {
            const currencyInfo = currenciesData.value.find(
              (currency: CurrencyItemType) => currency.underlyingCurrencyId === currencyItem.underlyingCurrencyId
            )
            if (!currencyInfo) {
              currenciesData.value.push(currencyItem)
            }
          })
        }
      }
      switch (item.type) {
        case filterItemTypeEnum.coa_account:
          newItem.value = item.value?.chartOfAccountId || ''
          onAccountChange(item.value?.chartOfAccountId || '')
          break
        case filterItemTypeEnum.auxiliaryCode:
          newItem.value =
            item.value.map(
              (auxiliaryItem: any) =>
                auxiliaryItem?.auxiliaryItemId || auxiliaryItem?.counterpartyId || auxiliaryItem?.entityAccountId || ''
            ) || []
          newItem.operator = item.operator || 'IN'
          if (!isEmpty(item.auxiliaryType)) {
            const currentType = auxiliaryCodeTreeOptions.value.find(
              (typeItem: any) => typeItem.id === item.auxiliaryType.auxiliaryTypeId
            )
            if (!currentType) {
              resetAuxiliaryCodeType(item.auxiliaryType)
            }
          }
          if (item.auxiliaryType.type === 'COUNTERPARTY') {
            newItem.options = currentCounterPartyOptions.value
          } else {
            const auxiliaryCodeTreeInfo = auxiliaryCodeTreeOptions.value.find(
              (typeItem: any) => typeItem.value === item.auxiliaryType?.auxiliaryTypeId
            )
            if (auxiliaryCodeTreeInfo) {
              newItem.options = auxiliaryCodeTreeInfo?.list || []
            }
          }
          break
        case filterItemTypeEnum.treasury_account:
          newItem.value = item.value.map((entityAccountItem: any) => entityAccountItem?.entityAccountId || '')
          break
        case filterItemTypeEnum.platform:
          newItem.value = item.value.map((platformItem: any) => platformItem?.platformId || '')
          break
        case filterItemTypeEnum.categorizing:
          newItem.value = item.value
          break
        default:
          break
      }
      return newItem
    })
  }
}

watch(
  () => model.value,
  (newVal: any) => {
    if (newVal) {
      if (props.dataSourceType === ReconciliationTaskDataSetEnum.LEDGER) {
        findHasCoaAccount()
      }
      if (isAddDataSourceModel.value) {
        conditionSelectOptions.value = [
          {
            type: '',
            auxiliaryTypeId: '',
            operator: '',
            value: [],
            options: []
          }
        ]
        if (chartOfAccountList.value.length) {
          conditionSelectOptions.value[0].type = filterItemTypeEnum.coa_account
          conditionSelectOptions.value[0].value = chartOfAccountList.value[0].value?.chartOfAccountId
          findChartOfAccountById(chartOrAccountData.value, conditionSelectOptions.value[0].value)
          onAccountChange(conditionSelectOptions.value[0].value)
        }
      } else {
        editDataSetInfo()
      }
    }
  },
  { immediate: true }
)

onMounted(initDataSource)
</script>

<style lang="scss">
.elv-reconciliation-task-data-source-filter-type-dropdown {
  .elv-select-transaction-filter-type__all,
  .elv-select-transaction-filter-currency__all {
    width: 100%;
    box-sizing: border-box;
    padding-left: 8px;
    margin-right: 0px;
    border-top: 1px solid #edf0f3;
    border-bottom: 1px solid #edf0f3;

    &:first-of-type {
      border-top: 0px;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .el-checkbox-group {
    .el-checkbox {
      .el-checkbox__label {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  &.journal-type {
    padding: 0px;

    .el-select-dropdown__empty {
      padding: 0px;
    }
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item {
    padding-left: 8px;

    &.selected::after,
    &.is-selected::after {
      width: 0;
      height: 0;
    }
  }
}

.elv-reconciliation-task-data-source-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 74px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-reconciliation-task-data-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #0e0f11;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #aaafb6;
        position: absolute;
        white-space: nowrap;
        top: 20px;
      }
    }

    button {
      top: 13px;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Plus Jakarta Sans';

    .elv-reconciliation-task-data-source-dialog-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-filters {
        margin-bottom: 16px !important;

        &-add-btn {
          cursor: pointer;
          line-height: 24px;
          font-size: 13px;
          font-weight: 400;
          color: #1753eb;
          display: flex;
          align-items: center;
          margin-top: 8px;

          svg {
            margin-right: 4px;
          }

          &.is-disabled {
            pointer-events: none;
            color: #aaafb6;
          }
        }

        &-items {
          .elv-reconciliation-task-data-source-dialog-content-filters-items-select {
            display: flex;
            align-items: center;
            margin-top: 8px;

            &:first-child {
              margin-top: 0px;
            }

            .elv-reconciliation-task-data-source-dialog-content-filters-items-delete-btn {
              cursor: pointer;
              margin-left: 8px;

              &:hover {
                fill: #1e2024;
              }
            }
          }

          .el-select {
            &.is-not-underlined {
              .el-select__wrapper.is-hovering {
                border-bottom: 0px !important;
              }
            }

            .el-select__wrapper {
              min-height: 44px;
              height: fit-content;
              padding: 0px 8px 0px 12px;

              &.is-hovering {
                box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
              }

              .el-select__selection {
                gap: 3px;
              }

              .el-tag {
                border-radius: 3px;
                border: 1px solid #e3e7f1;
                background: #fff;
                display: flex;
                padding: 0px 4px 0px 8px;
                justify-content: center;
                align-items: center;
                gap: 2px;
                box-sizing: border-box;
                width: fit-content;

                .el-tag__content {
                  color: #0e0f11;
                  font-family: 'Plus Jakarta Sans';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 28px;

                  .el-select__tags-text {
                    display: flex;
                    align-items: center;

                    img {
                      width: 14px;
                      height: 14px;
                      display: block;
                      border-radius: 50%;
                      margin-right: 4px;
                    }
                  }
                }

                .el-icon.el-tag__close {
                  width: 12px;
                  height: 12px;
                  color: #aaafb6;
                  margin-left: 2px;

                  &:hover {
                    color: #fff;
                    background-color: #909399;
                  }
                }
              }
            }

            .el-select__selected-item {
              display: flex;

              .el-tag {
                border-radius: 3px;
                border: 1px solid #e3e7f1;
                background: #fff;
                display: flex;
                padding: 0px 4px 0px 8px;
                justify-content: center;
                align-items: center;
                gap: 2px;
                box-sizing: border-box;
                width: fit-content;

                .el-select__tags-text {
                  color: #0e0f11;
                  font-family: 'Plus Jakarta Sans';
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 28px;
                }

                .el-icon.el-tag__close {
                  width: 12px;
                  height: 12px;
                  margin-left: 2px;
                }
              }
            }
          }
        }

        .elv-reconciliation-task-data-source-filter-item {
          margin-right: 8px;
        }

        .elv-reconciliation-task-data-source-filter-auxiliary-code {
          margin-right: 8px;
        }

        .el-form-item__content {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      &-tip {
        font-size: 12px;
        color: #636b75;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;
      }

      .elv-reconciliation-task-data-source-dialog-removed-tip {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #1e2024;
        line-height: 28px;
        display: flex;
        align-items: center;
      }

      .elv-reconciliation-task-data-source-dialog-save-btn {
        margin-top: 26px;
        width: 100px;
        height: 44px;
        background-color: #1753eb;

        &.is-disabled {
          background: #edf0f3;
          border: 1px solid #edf0f3;
          color: #aaafb6;
        }
      }
    }

    .elv-reconciliation-task-data-source-dialog-content-header {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .elv-reconciliation-task-data-source-dialog-content-header__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;

        p {
          color: #838d95;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          span {
            color: #1e2024;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
